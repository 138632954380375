<template>
  <div class="mainPage">
    <div class="mainPage__Content">
      <div class="Content__Header">
        <img class="Content__Header--logo" src="@/assets/img/watLabLandingLogo.png" alt="Logo">
        <div class="Content__Header--menuButton">
          <a href="#"><i class="fas fa-ellipsis-v"></i></a>
        </div>
      </div>
      <div class="Content__Info">
        <h2>{{$t('main_page_text1')}}</h2>
        <h1>{{$t('main_page_text2')}}</h1>
      </div>
      <div class="Content__Footer">
        <div class="Content__Footer__Contact">
          <p class="Content__Footer__Contact--eContact">
            <a href="mailto:testers@wearetesters.com">testers@wearetesters.com</a> |
            <a href="www.wearetesters.com">www.wearetesters.com</a>
          </p>
          <!-- <p>
            <span>Stiga Madrid.</span> |
            <span>Lopez de Hoyos, 141. Madrid.</span> |
            <span>+34 913 005 119</span>
          </p> -->
        </div>
        <div class="Content__Footer__Credits">
          <p>Powered by</p>
          <div class="Content__Footer__Credits--logos">
            <img src="@/assets/img/logoWAT.png" alt="Logo We Are Testers">
          </div>
        </div>
      </div>
    </div>
    <div class="mainPage__bgImage"  :style="{ backgroundImage: `url(${ bgImage })` }"></div>
    <div class="mainPage__bgLogo"   :style="{ backgroundImage: `url(${ bgLogo  })` }"></div>
  </div>
</template>

<script>
import CoverImage     from '@/assets/img/LandingCoverImage.jpg';
import BackgroundLogo from '@/assets/img/logo.svg';

export default {
  name: 'MainPage',

  data ( ) {
    return {
      bgImage : CoverImage,
      bgLogo  : BackgroundLogo
    }
  }
}
</script>

<style scoped lang="scss">
  .mainPage {
    height            : 100%;
    padding           : 3rem;
    background        : #000f40;
    color             : #ffffff;

    @media screen and ( max-width: 576px ) {
      padding         : 1.5rem;
    }

    &__Content {
      position        : relative;
      z-index         : 2;
      height          : 100%;
      display         : flex;
      flex-direction  : column;
      justify-content : space-between;
    }

    .Content {

      &__Header {
        display           : flex;
        justify-content   : space-between;
        align-items       : flex-start;

        &--logo {
          width           : 240px;

          @media screen and ( max-width: 768px ) {
            width         : 180px;
          }
        }

        &--menuButton {
          font-size       : 1.5rem;
          margin-top      : -5px;
        }
      }

      &__Info {
        width           : 50%;

        @media screen and ( max-width: 768px ) {
          width         : 100%;
        }

        h2 {
          font-size     : 1.2rem;
          font-weight   : lighter;
          text-transform: uppercase;
          letter-spacing: 5px;

          @media screen and ( max-width: 576px ) {
           font-size    : .9rem;
          }
        }

        h1 {
          font-size     : 3.5rem;
          line-height   : 4rem;

          @media screen and ( max-width: 576px ) {
           font-size    : 2.2rem;
           line-height  : 2.6rem;
          }
        }
      }

      &__Footer {
        display             : flex;
        justify-content     : space-between;
        font-size           : .9rem;
        line-height         : 1.3rem;
        color               : #e9e9e9;

        @media screen and ( max-width: 768px ) {
          flex-direction    : column-reverse;
        }

        &__Contact {
          @media screen and ( max-width: 768px ) {
            displa          : flex;
            justify-content : space-between;
            font-size       : .8rem;
            padding-top     : 2rem;
          }
          @media screen and ( max-width: 576px ) {
            flex-direction  : column;
            * {
              text-align    : center;
            }
          }

          span {
            display         : inline-block;
          }

          &--eContact {
            font-weight     : bold;
          }
        }

        &__Credits {
          display           : flex;
          align-items       : flex-end;

          @media screen and ( max-width: 768px ) {
            flex-direction  : column;
          }
          @media screen and ( max-width: 576px ) {
            flex-direction  : row;
            justify-content : center;
          }
          @media screen and ( max-width: 348px ) {
            flex-direction  : column;
            align-items     : center;
          }

          p {
            font-size       : .7rem;
            letter-spacing  : 2px;
            text-transform  : uppercase;
          }

          &--logos * {
            padding-left    : 1rem;
          }

        }
      }
    }

    &__bgImage {
      position        : fixed;
      z-index         : 0;
      top             : 0;
      right           : 0;
      width           : 50%;
      height          : 100%;
      background      : #000f40 no-repeat center center;
      background-size : cover;

      @media screen and ( max-width: 768px ) {
        width         : 100%;
        height        : 70%;
        top           : 15%;
      }
      @media screen and ( max-width: 576px ) {
        height        : 100%;
        top           : 0;
      }
    }

    &__bgLogo {
      opacity         : .05;
      position        : fixed;
      z-index         : 1;
      bottom          : -90px;
      left            : -50px;
      width           : 600px;
      height          : 600px;
      background      : no-repeat center center;

      @media screen and ( max-width: 1200px ) {
        width         : 500px;
        height        : 500px;
      }
      @media screen and ( max-width: 992px ) {
        width         : 400px;
        height        : 400px;
      }
      @media screen and ( max-width: 768px ) {
        display       : none;
      }
    }
  }

  a, a:visited {
    color       : #dadada;
    transition  : color 500ms;
  }
  a:hover, a:active {
    color       : #ffffff;
  }

  ::selection {
    background  : #ffffff;
    color       : #000f40;
  }
</style>
